<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <div style="font-size: 16px; font-weight: bold; margin-bottom: 15px">基本信息</div>
    <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:6}"
        :wrapper-col="{span:18}">
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="账号" prop="account">
            <a-input disabled v-model="rowData.account" placeholder="请输入账号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="经销商名称" prop="name">
            <a-input disabled v-model="rowData.name" placeholder="请输入经销商名称"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="曾用名" prop="usedName">
            <a-input disabled v-model="rowData.usedName" placeholder="请输入曾用名"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="经销商标签">
            <a-select disabled placeholder="请选择" mode="multiple" v-model="rowData.cusTagIdList">
              <a-select-option :value="item.id" v-for="(item, index) in cus_tag" :key="index">
                {{ item.tagName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="联系人" prop="contactPerson">
            <a-input disabled v-model="rowData.contactPerson" placeholder="联系人"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="联系方式" prop="mobile">
            <a-input disabled v-model="rowData.mobile" placeholder="手机号"></a-input>
            <div style="color: #FF7744; font-size: 12px">初始密码为手机号后六位</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="备用联系人" prop="alternateContact">
            <a-input disabled v-model="rowData.alternateContact" placeholder="备用联系人"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="备用联系方式" prop="alternateMobile">
            <a-input disabled v-model="rowData.alternateMobile" placeholder="备用联系人手机号"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="邮箱" prop="email">
            <a-input disabled v-model="rowData.email" placeholder="请输入邮箱"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="纳税识别号" prop="receiptNumber">
            <a-input disabled v-model="rowData.receiptNumber" placeholder="纳税识别号"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="银行账号" prop="bankAccount">
            <a-input disabled v-model="rowData.bankAccount" placeholder="银行账号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="发票抬头" prop="receiptInvoice">
            <a-input disabled v-model="rowData.receiptInvoice" placeholder="发票抬头"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="经销商区域" prop="receiptInvoice">
            <PCD
              disabled
              placeholder="请选择区域"
              :province.sync="rowData.province"
              :city.sync="rowData.city"
              :district.sync="rowData.county"
              :provinceId.sync="rowData.provinceId"
              :cityId.sync="rowData.cityId"
              :districtId.sync="rowData.countyId"
            >
            </PCD>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="详细地址" prop="address">
            <a-textarea disabled v-model="rowData.address" :max-length="50" placeholder="请输入详细地址"></a-textarea>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="折扣政策" prop="rebatePolicy">
            <a-input disabled v-model="rowData.rebatePolicy" placeholder="折扣政策"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="合同有效期" prop="contractValidity">
            <DatePicker disabled :startTime.sync="rowData.contractValidity"></DatePicker>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
      <a-form-model-item label="销售模式" prop="salesModel" :autoLink="false" ref="test">
        <a-select disabled placeholder="请选择" @change="handleChange" v-model="rowData.salesModel">
          <a-select-option :value="item.key" v-for="(item, index) in options" :key="index">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="增值税率" prop="vatRate">
            <a-input disabled v-model="rowData.vatRate" placeholder="增值税率" addon-after="%"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="编码" prop="cusCode">
            <a-input disabled v-model="rowData.cusCode" placeholder="编码"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="保证金" prop="deposit">
            <a-input disabled v-model="rowData.deposit" @blur="rowData.deposit = parseFloat(rowData.deposit) ? Math.abs(parseFloat(rowData.deposit)).toFixed(2) : ''" placeholder="保证金"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="状态" prop="status">
            <a-switch disabled v-model="status" checked-children="启用" un-checked-children="冻结" default-checked />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="备注说明" prop="remark" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
            <a-textarea disabled v-model="rowData.remark" placeholder="请输入"></a-textarea>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div style="font-size: 16px; font-weight: bold; margin-bottom: 15px" v-if="dataList.length > 0">收货地址</div>
      <div v-for="(item, index) in dataList" :key="index">
        <a-row>
          <a-col :span="11">
            <a-form-model-item label="收货地址" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
              <PCD
                disabled
                placeholder="请选择区域"
                :province.sync="item.province"
                :city.sync="item.city"
                :district.sync="item.county"
                :provinceId.sync="item.provinceId"
                :cityId.sync="item.cityId"
                :districtId.sync="item.countyId"
              >
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="详细地址" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
              <a-textarea disabled v-model="item.address" :max-length="50" placeholder="请输入详细地址"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-button style="margin-top: 4px; margin-left: 20px" type="primary" @click="onDelAddres(item)"
              >删除</a-button
            >
          </a-col>
        </a-row>
      </div>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="添加地址">
            <a-button disabled type="primary" @click="onAdd()">添加收货地址</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
let busSiteNameText = (rule, value, callback) => {
  if (/[\u4E00-\u9FA5]/g.test(value)) {
    callback(new Error('账号不能使用文字'))
  } else {
    callback()
  }
}

import { editCustomerInfo, selectByIdCustomerInfo, addCustomerInfo } from '../../customer/api/CustomerInfoApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      status: true,
      cus_tag: [],
      options: [
        {
          key: 1,
          label: '内销',
        },
      ],
      dataList: [],
      // 表单验证
      formRule: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        cusCode: [
          {required: true, message: '请输入编码', trigger: 'blur'}
        ],
        mobile: [
          {required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入手机号', trigger: 'blur'}
        ],
        email: [
          {pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: '请输入正确的邮箱地址', trigger: 'blur'}
        ],
        bankAccount: [
          {pattern: new RegExp(/^[1-9]\d{9,29}$/), message: '请输入正确格式的银行卡号', trigger: 'blur'}
        ],
        remark: [
          {required: false, message: '请输入备注', trigger: 'blur'}
        ],
        account: [
          {required: true, validator: busSiteNameText, trigger: 'blur'}
        ],
        contactPerson: [
          {required: false, message: '请输入联系人', trigger: 'blur'}
        ],
        rebatePolicy: [
          {required: false, message: '请输入折扣政策', trigger: 'blur'}
        ],
        salesModel: [
          {required: false, message: '请选择销售模式', trigger: 'change'}
        ],
        receiptNumber: [
          {pattern: new RegExp(/^[0-9a-zA-Z_]*$/), message: '发票号不能使用文字', trigger: 'blur'}
        ],
        receiptInvoice: [
          {required: false, message: '请输入发票抬头', trigger: 'blur'}
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ],
        account: [
          { required: true, validator: busSiteNameText, trigger: 'blur' }
        ],
        contactPerson: [
          { required: false, message: '请输入联系人', trigger: 'blur' }
        ],
        rebatePolicy: [
          { required: false, message: '请输入折扣政策', trigger: 'blur' }
        ],
        salesModel: [
          { required: false, message: '请选择销售模式', trigger: 'change' }
        ],
        receiptNumber: [
          { required: false, message: '请输入纳税识别号', trigger: 'blur' }
        ],
        receiptInvoice: [
          { required: false, message: '请输入发票抬头', trigger: 'blur' }
        ],
        contractValidity: [
          { required: false, message: '请输入合同有效期', trigger: 'blur' }
        ],
        vatRate: [
          { pattern: new RegExp(/^([0-9]{1,2}|100)$/), message: '请输入0-100之间的增值率', trigger: 'blur' }
        ],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.cus_tag = []
      this.dataList = []
      // this.rowData = row
      if (handle == 'edit' || handle == 'check') {
        this.axios.get('/api/customer/customer/customerInfo/selectById/' + row.customerId).then((res) => {
          this.rowData = res.body
        })
      }
      this.axios.get('/api/customer/customer/customerTag/customerTagList?flagEnable=true').then(res => {
        this.cus_tag = res.body
      })

    },

    // 删除收获地址
    onDelAddres(row) {
      this.dataList.splice(
        this.dataList.findIndex((e) => {
          return e == row
        }),
        1
      )
    },

    handleChange(value) {
      this.$refs.test.onFieldChange()
      console.log(value)
    },
    // handleCusTagChange(value) {
    //     this.rowData = value
    // },
    onChange(value) {
      console.log(value)
    },
    onAdd() {
      let obj = {
        provinceId: '', // 省ID
        province: '', // 省名称
        cityId: '', // 市ID
        city: '', // 市名称
        countyId: '', // 区县ID
        county: '', // 区县名称
        address: '', // 详细地址
      }
      this.dataList.push(obj)
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.status = this.status
        this.rowData.addresses = this.dataList
        const res = this.handle === 'add' ? await addCustomerInfo(this.rowData) : await editCustomerInfo(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.rowData = {}
          this.visible = false
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {
    
  },
}
</script>

<style lang="less" scoped>
</style>
